import React, { useState, useEffect } from "react";
import {atom, useAtom} from 'jotai';
import sift from 'sift';


/**
 * 
 * fitment (coming from MGP wordpress site)
 * 
 *
 */

const fitment_obj = atom(null);



/**
 * 
 * products
 * 
 *
 */

// THIS IS THE GLOBAL products DATA OBJECT
export const products_state = atom({ 
  activeId: null,
  activeObj: null,
  array: null, 
  isPrimed: false 
});

// function to update products_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_products_activeId = atom(null);

// holds the fetched product array
const all_products_array = atom(null);

// *
// plates are referenced in product objects
// *
const all_plates_array = atom(null);



/**
 * 
 * components
 * 
 */

// THIS IS THE GLOBAL components DATA OBJECT
export const components_state = atom({
  activeId: null, 
  activeObj: null,
  array: null, 
  isPrimed: false 
});

// function to update components_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_components_activeId = atom(null);

// holds the fetched components array
const all_components_array = atom(null);

// *
// sections are referenced in components objects
// *
const all_sections_array = atom(null);



/**
 * 
 * items
 * 
 */

// THIS IS THE GLOBAL items DATA OBJECT
export const items_state = atom({ activeIdObj: null, array: null, isPrimed: false });

// function to update items_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_items_activeIdObj = atom(null);

// holds the fetched items array
const all_items_array = atom(null);

// holds the fetched items_list arrays
const items_list_arrays = atom(null);






/**
 * 
 * 
 * MISC
 * 
 * 
 */


// used to make the dynamic engraving and bolts textures on caliper covers
export const engraving_texture_obj = atom(null);
export const bolts_texture_obj = atom(null);

// distinguishes between "standalone" mode and "drive" mode
export const mode_state = atom("standalone");

// holds info about the platform, which is the root of the interactable scene
export const platform_state = atom({
  isLoaded: false, 
  rotationGroup: null,
  animationSpeed: 0,
});









// data passed in from the UrlDataController that determines activeId's
export function GlobalDataManagers({
  // products
  products_activeId_fromURL,
  update_products_activeId_inURL,
  // components
  components_activeId_fromURL,
  update_components_activeId_inURL,
  // items
  items_activeIdObj_fromURL,
  update_items_activeIdObj_inURL,
}) {  


  /**
   * 
   * Load the data required to initialize the experience 
   * 
   */

  const [isInitDataLoaded, setIsInitDataLoaded] = useState(false);
  useEffect(() => {
    loadInitData();
  }, [])
  async function loadInitData() {
    await Promise.all([
      loadFitmentObj(),
      loadProductData(),
      loadPlatesData(),
      loadComponentsData(),
      loadSectionsData(),
      loadItemsData(),
      loadItemsListData()
    ])
    setIsInitDataLoaded(true)
  }



  /**
   * 
   * fitment (coming from MGP wordpress site)
   * 
   *
  */

  const [fitmentObj, setfitmentObj] = useAtom(fitment_obj);

  async function loadFitmentObj() {
    // TODO: get fitment obj from wordpress
    let fitmentObj_wordpress = undefined; 

    if (!fitmentObj_wordpress) {
      // set default fitment obj
      fitmentObj_wordpress = {
        year: "2013",
        make: "bmw",
        model: "128i",
        sub_model: "base",
        plate_code_front: "F2",
        plate_code_rear: "R2",
        part_number: "22200S",
        base_price: "240.00"
      }
    }

    setfitmentObj(fitmentObj_wordpress);
    
  } 





  /**
   * 
   * products
   *    also, plates are nested inside products
   * 
   */

  const [productsState, setProductsState] = useAtom(products_state);
  const [requested_products_activeId] = useAtom(update_products_activeId);
  const [allProductsArray, setAllProductsArray] = useAtom(all_products_array);
  const [allPlatesArray, setAllPlatesArray] = useAtom(all_plates_array);
  
  
  // load products for experience to start
  async function loadProductData() {
    let res = await fetch('/data/products.json');
    let allProducts = await res.json();
    setAllProductsArray(allProducts);
  }
  async function loadPlatesData() {
    let res = await fetch('/data/plates.json');
    let allPlates = await res.json();
    setAllPlatesArray(allPlates);
  }

  // when some child tells us to update products_state.activeId, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_products_activeId) update_products_activeId_inURL(requested_products_activeId);
  }, [requested_products_activeId]) 
  
  // when URL tells us there is a new active product, update products_state
  useEffect(() => {
    if (!isInitDataLoaded || !products_activeId_fromURL) return;
    let productsArray = filterProductData();
    let activeProduct = productsArray.find((productObj) => {return productObj._id === products_activeId_fromURL});
    let newProductsState = {
      activeId: products_activeId_fromURL, 
      activeObj: activeProduct,
      array: productsArray, 
      isPrimed: true
    }
    setProductsState(newProductsState);
    console.log(`____ NEW productsState ______`, newProductsState)
  }, [products_activeId_fromURL, isInitDataLoaded])

  // filter and return an array with the active product
  function filterProductData() {
    let applicableProducts = allProductsArray.filter(sift({ _id: { $in: [products_activeId_fromURL] } }));
    // inject fitment data into product
    applicableProducts.forEach(productObj => {
      productObj.year = fitmentObj.year;
      productObj.make = fitmentObj.make;
      productObj.model = fitmentObj.model;
      productObj.sub_model = fitmentObj.sub_model;
      productObj.base_price = fitmentObj.base_price;
      productObj.displayName = `${fitmentObj.year} ${fitmentObj.make} ${fitmentObj.model} ${fitmentObj.sub_model}`;
      productObj.plates = {
        front: fitmentObj.plate_code_front,
        rear: fitmentObj.plate_code_rear 
      }
    });
    // inject correct plate objects
    applicableProducts.forEach(productObj => {
      // TODO: change this to use plates keys in case there are more cases than just front and rear
      let plateId_f = productObj.plates.front;
      let plateId_r = productObj.plates.rear;
      productObj.plates.front = allPlatesArray.find((plateObj) => plateObj._id === plateId_f);
      productObj.plates.rear = allPlatesArray.find((plateObj) => plateObj._id === plateId_r);
    });
    return applicableProducts;
  }






  /**
   * 
   * components
   *    also, sections are nested inside components
   *    also, items/items_list are nested inside components
   * 
   */

  const [componentsState, setComponentsState] = useAtom(components_state);
  const [requested_components_activeId] = useAtom(update_components_activeId);
  const [allComponentsArray, setAllComponentsArray] = useAtom(all_components_array);
  const [allSectionsArray, setAllSectionsArray] = useAtom(all_sections_array);
  const [itemsListArrays, setItemsListArrays] = useAtom(items_list_arrays);

  // load components for experience to start
  async function loadComponentsData() {
    let res = await fetch('/data/components.json');
    let allComponents = await res.json();
    setAllComponentsArray(allComponents);
  }
  async function loadSectionsData() {
    let res = await fetch('/data/sections.json');
    let allSections = await res.json();
    setAllSectionsArray(allSections);
  }

  // when some child tells us to update components_state.activeId, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_components_activeId) update_components_activeId_inURL(requested_components_activeId);
  }, [requested_components_activeId])
  
  // when active product changes, we tell the URL to update the active component to be the product's first component
  // unless active component is already set in URL (i.e. shopper returning to a saved config)
  useEffect(() => {
    if (!productsState.activeId || components_activeId_fromURL) return;
    let activeProduct = productsState.array.find((productObj) => productObj._id === productsState.activeId);
    update_components_activeId_inURL(activeProduct.components[0]);
  }, [productsState.activeId])

  // when URL tells us there is a new active component, update components_state
  useEffect(() => {
    if (!components_activeId_fromURL || !productsState.isPrimed) return;
    let componentChoicesArray = filterComponentData();
    let newComponentsState = {activeId: components_activeId_fromURL, array: componentChoicesArray, isPrimed: true}
    setComponentsState(newComponentsState);
    console.log(`____ NEW componentsState ______`, newComponentsState)
  }, [components_activeId_fromURL, productsState.isPrimed])


  // filter and return an array with the list of components
  function filterComponentData() {
    let applicableComponentIds = productsState.array.find((productObj) => productObj._id === productsState.activeId).components;
    let applicableComponents = allComponentsArray.filter(sift({ _id: { $in: applicableComponentIds } }));
    // make applicableComponents have same order as components array on products object
    applicableComponents.sort((a, b) => {return applicableComponentIds.indexOf(a._id) - applicableComponentIds.indexOf(b._id)});
    // inject correct sections objects and items_list
    applicableComponents.forEach(componentObj => {
      // sections
      let sectionIds = componentObj.sections;
      sectionIds.forEach((sectionId, index) => {
        componentObj.sections[index] = allSectionsArray.find((sectionObj) => sectionObj._id === sectionId);
      })
      // items_list
      let itemIds = componentObj.items;
      itemIds.forEach((itemId, index) => {
        // replace _items_list value with the item values in the list
        if (itemId.includes('_items_list')) {
          let itemsArray_fromList = itemsListArrays[itemId];
          componentObj.items.splice(index, 1, ...itemsArray_fromList)
        }
      })
    });
    return applicableComponents;
  }







  
  /**
   * 
   * items
   * 
   */

  
  const [itemsState, setItemsState] = useAtom(items_state);
  const [requested_items_activeIdObj] = useAtom(update_items_activeIdObj);
  const [allItemsArray, setAllItemsArray] = useAtom(all_items_array);

  // load items for experience to start
  async function loadItemsData() {
    let res = await fetch('/data/items.json');
    let allItems = await res.json();
    setAllItemsArray(allItems);
  }
  // load items_list for experience to start
  async function loadItemsListData() {
    let [color_items_list, engravings_default_items_list] = await Promise.all([
      loadColorItems(),
      loadEngravingItems(),
    ])
    setItemsListArrays({
      "color_items_list": color_items_list,
      "engravings_default_items_list": engravings_default_items_list,
    });
  }
  async function loadColorItems() {
    let res = await fetch('/data/items_list/color_items_list.json');
    let array = await res.json();
    return array;
  }
  async function loadEngravingItems() {
    let res = await fetch('/data/items_list/engravings_default_items_list.json');
    let array = await res.json();
    return array;
  }

  // when some child tells us to update items_state.activeIdObj, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_items_activeIdObj) update_items_activeIdObj_inURL(requested_items_activeIdObj);
  }, [requested_items_activeIdObj])
  
  // when active product changes & components_state is primed, we tell the URL to update the activeIdObj to each component's default item
  // unless activeIdObj is already set in URL (i.e. shopper returning to a saved config)
  useEffect(() => {
    if (!componentsState.isPrimed || items_activeIdObj_fromURL) return;
    update_items_activeIdObj_inURL(defaultItemActiveIdObj());
  }, [productsState.activeId, componentsState.isPrimed])


  // when URL tells us there is a new items.activeIdObj, update items_state
  useEffect(() => {
    if (!items_activeIdObj_fromURL || !componentsState.isPrimed) return;
    let newItemsState = {activeIdObj: items_activeIdObj_fromURL, array: allItemsArray, isPrimed: true}
    setItemsState(newItemsState);
    console.log(`____ NEW itemsState ______`, newItemsState)
  }, [items_activeIdObj_fromURL, componentsState.isPrimed])


  // this is hardcoded because it's going to be the same every time
  // in other projects, we'll use a "default_item" key or choose the first item in the list,
  function defaultItemActiveIdObj() {
    let activeIdObj = {};
    componentsState.array.forEach((componentObj) => {
      if (componentObj._id === 'baseColor_default') {
        activeIdObj[componentObj._id] = "red_powder_coat";
      }
      else if (componentObj._id === 'bolts_default') {
        activeIdObj[componentObj._id] = {bolts: "bolts_default", color: "silver"};
      }
      else if (componentObj._id.includes('engraving')) {
        activeIdObj[componentObj._id] = {
          front: {engraving: "mgp_default", color: "silver"}, 
          rear: {engraving: "mgp_default", color: "silver"}
        };
      }
    })
    return activeIdObj;
  }









  return null

}
