/** SpinControls are used to allow user to grab platform and rotate */

import { useEffect, useRef } from 'react';
import { useFrame, useThree } from "@react-three/fiber";

export const SpinControls = ({ platformGltf }) => {

  const platform = platformGltf.getObjectByName("platform-rotation-group");
  const isSpinActiveRef = useRef(false);
  const prevMousePosRef = useRef(0);
  const currentMousePosRef = useRef(0);
  const gl = useThree(({ gl }) => gl);
  const canvasWidth = useRef(gl.domElement.clientWidth);

  useEffect(() => {
    gl.domElement.onpointerdown = beginTracking;
    gl.domElement.onpointermove = updateTracking;
    gl.domElement.onpointerup = endTracking;
    window.onresize = function() { canvasWidth.current = gl.domElement.clientWidth };
  }, []);

  function beginTracking(e) {
    isSpinActiveRef.current = true;
    window.isUserRotatingPlatform = true;
  }
  
  function updateTracking(e) {
    if (!isSpinActiveRef.current) return;
    currentMousePosRef.current = e.x;
    if (!prevMousePosRef.current) prevMousePosRef.current = e.x;
  }

  function endTracking() {
    isSpinActiveRef.current = false;
    window.isUserRotatingPlatform = false;
    prevMousePosRef.current = 0;
    currentMousePosRef.current = 0;
  }

  useFrame(() => {
    if (isSpinActiveRef.current && platform)
      spinPlatform();
  })
    
  function spinPlatform() {
    var mousePosChange = currentMousePosRef.current - prevMousePosRef.current;
    var rotChange = (mousePosChange / canvasWidth.current) * 3;
    var newRotY = platform.rotation.y + rotChange;
    prevMousePosRef.current = currentMousePosRef.current;

    if (Math.abs(newRotY) > 0.8) return; // restrict rotation (radians)

    platform.rotation.set(0, newRotY, 0);
  }

  return null;

}
