import * as THREE from 'three';
import React, { Suspense, useState, useMemo, useEffect, useRef } from "react";
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber";
import { OrbitControls, Stats, PresentationControls } from "@react-three/drei";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EnvironmentController from './EnvironmentController'
import {SpinControls} from './SpinControls'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { KTX2Loader } from "../../../scripts/PatchedKTX2Loader.js";
import { SvgToPng } from './SvgToPng/SvgToPng';
import AssetSystem3d, {
  useAssetLoader
} from "../dataManagers/AssetSystem3d";

import {atom, useAtom} from 'jotai';
import {
  products_state, 
  update_products_activeId,
  items_state,
  update_items_activeIdObj,
  engraving_texture_obj,
  bolts_texture_obj,
  mode_state,
  platform_state
} from '../../components/dataManagers/GlobalDataManagers';



export default function Scene() {

  // TODO: potentially move these into SvgToPng and Engraving components
  const [engravingTextureObj, setEngravingTextureObj] = useAtom(engraving_texture_obj);
	const [boltsTextureObj, setBoltsTextureObj] = useAtom(bolts_texture_obj);

  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
		<>

			{/* not visible to user. handles svg to png conversion for engravings */}
      <SvgToPng setEngravingTextureObj={setEngravingTextureObj} setBoltsTextureObj={setBoltsTextureObj}/>

			{/* three scene's canvas */}
			<Canvas
				id="builder-scene-canvas-container"
				className='shared-scene-sizing builder-scene-canvas-container'
				camera={{
          position: [0, 0.5, 2.25],
          rotation: [0, 0, 0],
          fov: isMobile ? 40 : 45,
          near: 0.1,
          far: 20
        }}
				dpr={window.devicePixelRatio}
			>

					<SetupRenderer/>

					{/* <OrbitControls
						autoRotate={false}
						minDistance={isMobile ? 0.1 : 0.1}
						maxDistance={20}
						maxPolarAngle={1.57}
						enableKeys={false}
						enablePan={false}
					/> */}

          <AssetSystem3d>
            <Suspense fallback={null}>
              
              {/* environment assets, lighting, etc.  */}
              <EnvironmentController/>

              <PlatformAndWheelsController />

            </Suspense>
            
            <PlatesController />
          </AssetSystem3d>



						{/* baseColor layer of caliper */}
            {/* <CaliperBaseColor /> */}

						{/* engraving layer of caliper */}
            {/* {engravingTextureObj && 
              <DynamicRectangleCaliperEngraving 
              textureBase64Src={engravingTextureObj.textureBase64Src} 
              srcImgAspectRatio={engravingTextureObj.srcImgAspectRatio} 
              />
            } */}


            {/* bolts layer of caliper */}
            {/* {boltsTextureObj &&
              <CaliperBolts 
                textureBase64Src={boltsTextureObj.textureBase64Src} 
              />
            } */}

          
						

			</Canvas>
		</>
  );
}



// loads platform model (comes with wheels)
// will handle rotation controls (probably via sub component)
// will handle the animation for platform road and wheels (probably via sub component)
// will handle wheel material (probably via sub component)
function PlatformAndWheelsController({children}) {

  
  // load platform model
	const platformModel = useLoader(GLTFLoader, '/mgp-caliper-models/scenery/models/platform-with-wheels.glb', (loader) => {
    loader.setKTX2Loader( new KTX2Loader() );
	})

  // grab reference to the platform rotation group
  const platformRotationGroup = platformModel.scene.children[0];
  
  // now that we're loaded, set platformState
  const [platformState, setPlatformState] = useAtom(platform_state);
  useEffect(() => {
    setPlatformState({
      ...platformState,
      isLoaded: true,
      rotationGroup: platformRotationGroup
    });
  }, [])
  
  // grab reference to the wheels
  const wheels_ref = useRef(platformRotationGroup.getObjectByName('wheels'));

  // handle change between standalone and drive mode
  const [mode] = useAtom(mode_state);
  useEffect(() => {
    if (mode === 'standalone') {
      wheels_ref.current.visible = false;
    }
    else if (mode === 'drive') {
      wheels_ref.current.visible = true;
    }
  }, [mode])


	return (
    <>
      <PresentationControls
        global={true} // Spin globally or by dragging the model
        snap={true} // Snap-back to center (can also be a spring config)
        speed={1} // Speed factor
        zoom={1} // Zoom factor when half the polar-max is reached
        rotation={[0, 0, 0]} // Default rotation
        polar={[0, 0]} // Vertical limits
        azimuth={[-1, 1]} // Horizontal limits
        config = { {mass: 1, tension: 170, friction: 26} } // Spring config
      >
        <primitive object={platformModel.scene} />
      </PresentationControls>
      {/* <SpinControls platformGltf={platformModel.scene} /> */}
    </>
  )

}

// will read the products_state and load the plate models specified
// will wait for platfrom to be loaded, then inject the loaded plate models
// will implement the controllers for the baseColor, engraving, and bolts mesh (separately for front and rear)
// will handle the transform animations between modes
function PlatesController() {

  const [productsState] = useAtom(products_state);
  const [platformState] = useAtom(platform_state);
  const [plateGroup, setPlateGroup] = useState();
  
  // load plate models
  useEffect(() => {
    if (productsState.isPrimed) loadPlateModels();
  }, [productsState.isPrimed])
  
  const getAsset = useAssetLoader();
  async function loadPlateModels() {

    let platePromises = [];

    // iterate plates and load their model
    for (let [key, plateObj] of Object.entries(productsState.activeObj.plates)) {
      let promise = new Promise(async (resolve) => {
        const model = await getAsset(plateObj.model);
        resolve(model);
      }) 
      platePromises.push(promise);
    }

    let [frontPlate, rearPlate] = await Promise.all(platePromises);

    // TODO: delete
    frontPlate.scene.children[0].scale.set(3, 3, 3)
    rearPlate.scene.children[0].scale.set(3, 3, 3)
    // TODO: delete

    let group = new THREE.Group();
    if (frontPlate.scene) group.add(frontPlate.scene.children[0])
    if (rearPlate.scene) group.add(rearPlate.scene.children[0])

    setPlateGroup(group);

  }

  useEffect(() => {
    if (platformState.rotationGroup && plateGroup)
      platformState.rotationGroup.add(plateGroup);
  }, [platformState.rotationGroup, plateGroup])



  return null
}



















function CaliperBolts({textureBase64Src}) {

  // const [productsObj] = useAtom(products_state);
	// console.log(`productsObj caliper`, productsObj)
  const [, setProductsActiveId] = useAtom(update_products_activeId);
  useEffect(() => {
    setTimeout(function() {
      // setProductsActiveId("012")
    }, 10000);
  }, [])


  const [itemsStateObj] = useAtom(items_state); 
  const [, setItemsActiveIdObj] = useAtom(update_items_activeIdObj);
  useEffect(() => {
    setTimeout(function() {
      // ____________________________________________________________________
      // testing update of nested values
      let newEngravingObj = {...itemsStateObj.activeIdObj["engravings_bmw"]};
      newEngravingObj["front"]["color"] = "black";
      let newActiveIdObj = {...itemsStateObj.activeIdObj, baseColor_default: "carbon_fiber", engravings_bmw: newEngravingObj};
      // ____________________________________________________________________
      
      // console.log(`newActiveIdObj`, newActiveIdObj)
      // setItemsActiveIdObj(newActiveIdObj);
    }, 10000);
  }, [])


  const scene = useThree(({ scene }) => scene);

  useEffect(() => {
    new GLTFLoader().load('/mgp-caliper-models/models/F2/TEST-F2-bolts.glb', (gltf) => {
      
      var mesh;
      gltf.scene.traverse((node) => {
        if (node.isMesh) {
          mesh = node;
        }
      })
      

      var normalMap;

      new THREE.TextureLoader().load('/mgp-caliper-models/textures/bolts/OLD/F2_bolts_nrm.png', (texture) => {
        texture.flipY = false;
        texture.encoding = THREE.LinearEncoding;
        normalMap = texture;
      
        // TEXTURE
        new THREE.TextureLoader().load(textureBase64Src, (texture) => {
    
          // TEXTURE manipulations
          texture.flipY = false;
          texture.encoding = THREE.sRGBEncoding;
    
          // MATERIALS
          let boltsMaterial = new THREE.MeshStandardMaterial({
            map: texture,
            normalMap: normalMap,
            opacity: 1,
            transparent: true,
            metalness: 0.5,
            roughness: 0.5,
            polygonOffset: true,
            polygonOffsetFactor: -5,
            polygonOffsetUnits: -10
          })
    
          mesh.material = boltsMaterial;
          mesh.material.needsUpdate = true;
      
          scene.add(gltf.scene);
    
        });  

      });
  
  
    })
  }, [])



  
  return null;

}




function DynamicRectangleCaliperEngraving({textureBase64Src, srcImgAspectRatio}) {


  const scene = useThree(({ scene }) => scene);

  function calcAspectRatio(boundingBox) {
    // width / height
    return (boundingBox.max.x - boundingBox.min.x) / (boundingBox.max.y - boundingBox.min.y);
  }

  useEffect(() => {
    if (!textureBase64Src || !srcImgAspectRatio) return null;


  
    new GLTFLoader().load('/mgp-caliper-models/models/F2/TEST-F2-engraving.glb', (gltf) => {
      var areBoltsActive = true;

      var mesh;
      var geometry;
      gltf.scene.traverse((node) => {
        if (node.isMesh) {
          mesh = node;
          geometry = node.geometry;
        }
      })
      
      if (areBoltsActive) {
        mesh.scale.set(0.9, 0.9, 1);
        mesh.position.set(mesh.position.x, mesh.position.y - 0.005, mesh.position.z)
      }

      var normalMap;
      new THREE.TextureLoader().load('/mgp-caliper-models/textures/engravings/marines-logo-nrm.png', (texture) => {
        texture.flipY = false;
        texture.encoding = THREE.LinearEncoding;
        normalMap = texture;

        new THREE.TextureLoader().load(textureBase64Src, (texture) => {
    
          // TEXTURE manipulations
          texture.flipY = false;
          texture.encoding = THREE.sRGBEncoding;
    
          // lower number increases size of texture
          var sizeFac = 2;
          // lower number moves texture down
          var yPosFac = 0;
    
          let uvAspectRatio = calcAspectRatio(geometry.boundingBox);
          let repeatXY;
  
          console.log(`uvAspectRatio`, uvAspectRatio)
          console.log(`srcImgAspectRatio`, srcImgAspectRatio)
    
          // no scaling needed. Logo will fit how it is
          if (srcImgAspectRatio > uvAspectRatio) repeatXY = 1;
          // scale down so the y-axis stretches to UV bounds (while maintaining aspect ratio)
          else repeatXY = Math.min(uvAspectRatio, uvAspectRatio * (1 / srcImgAspectRatio));
    
          texture.repeat.set(repeatXY, repeatXY);
          
          let xOffset = (repeatXY - 1) / 2 * -1;
          texture.offset.x = xOffset;
          
          var yPosChange = (yPosFac * sizeFac) * 0.1;
          let yOffset = yPosChange + ((repeatXY - 1) / 2 * -1);
          texture.offset.y = yOffset;
          
    
          // MATERIALS
          let engravingMaterial = new THREE.MeshStandardMaterial({
            map: texture,
            normalMap: normalMap,
            opacity: 1,
            transparent: true,
            metalness: 0.5,
            roughness: 0.5,
            polygonOffset: true,
            polygonOffsetFactor: -5,
            polygonOffsetUnits: -20
          })
          mesh.renderOrder = 1;
          mesh.material = engravingMaterial;
          mesh.material.needsUpdate = true;
       
    
          var alreadyHere = false;
          scene.traverse((node) => {
            if (node.name === 'caliper-engraving-dif')
              alreadyHere = true;
          })
          
          if (!alreadyHere)
            scene.add(gltf.scene);
    
       })
      })

      
  
  
    })
  }, [textureBase64Src])


  
  return null;

}



function CaliperBaseColor() {


  const scene = useThree(({ scene }) => scene);

  // MATERIALS
  // let baseColorMaterial = new THREE.MeshStandardMaterial({
  //   color: '#000000'
  // })

  useEffect(() => {
    new GLTFLoader().load('/mgp-caliper-models/models/F2/TEST-F2-baseColor.glb', (gltf) => {
      gltf.scene.traverse((node) => {
        if (node.isMesh) {
          // node.material = baseColorMaterial;
          // node.material.needsUpdate = true;
        }
      })
  
      console.log(`CaliperBaseColor`, gltf.scene)
      
      scene.add(gltf.scene);
  
    })
  }, [])

  
  return null;

}




function SetupRenderer() {

	const gl = useThree(({ gl }) => gl);
	useMemo(() => {
		gl.physicallyCorrectLights = true;
		gl.toneMapping = THREE.NoToneMapping;
	}, [gl])
	
	return null;
}

