import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import './styles.scss';

// stepper styles
const ipad = 767;
const md = 769;

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#000000', // black
    zIndex: 1,
    borderRadius: '50%',
    cursor: 'pointer',
    width: 20,
    height: 20,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)', // black
    [theme.breakpoints.up(ipad)]: {
      width: 20,
      height: 20,
    },
    [theme.breakpoints.up(md)]: {
      width: 20,
      height: 20,
    },
  },
  active: {
    backgroundColor: '#E62429', // red
    boxShadow: '0 4px 10px 0 rgba(230,36,41,.5)',
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

export default function CustomStepper({ stepperData, setStepper }) {

  const handleClick = (id) => {
    setStepper(id);
  }
  
  const getStepperActiveIndex = () => {
    return stepperData.array.findIndex(component => component._id === stepperData.activeId);
  }

  return (
    <Stepper className="Stepper" alternativeLabel activeStep={getStepperActiveIndex()} >
      { stepperData.array.map((component, index) => (
          <Step key={component.displayName} 
            onClick={() => { handleClick(component._id) }}
            onTouchStart={(e) => { e.preventDefault(); handleClick(component._id) }}
          >
            <StepLabel StepIconComponent={ColorlibStepIcon} >
              {component.displayName}
            </StepLabel>
          </Step>
      ))}
    </Stepper>
  );
}

CustomStepper.propTypes = {
  /**
   * Contains labels and activeIndex
   */
  stepperData: PropTypes.object,
  /**
   * Contains an array of colors for each step
   */
  stepColors: PropTypes.array,

  /**
   * Updates stepper activeIndex when clicked
   */
  setStepper: PropTypes.func
};