import PropTypes from 'prop-types';
import { HeadersBuildSection } from './HeadersBuildSection';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import './styles.scss';

export const BuildSection = ({ headerData, children }) => {
  const matches = useMediaQuery(`(min-width: 769px)`); // returns bool

  // sets the drawer position depending on viewport size
  const handleDrawerPosition = () => {
    if (matches) return { top: 0, left: 0, height: '100vh', width: '325px' }
    else return { bottom: 0, height: '55%', width: '100%' }
  }
  
  return (
    <div className="bg-white LeftComponentDrawer" style={ handleDrawerPosition() }>
      <Row>
        <Col>
          <HeadersBuildSection headerLabel= {headerData.activeObj.displayName} headerLineImgSrc='/images/mgpHeaderLine.svg' subheaderLabel='Part # - 85437483933'/>
        </Col>
      </Row>
      { children }
    </div>
  )
}