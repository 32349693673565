import React from "react";
import Canvg from 'canvg';
import SVGInject from '@iconfu/svg-inject'
import './styles.scss'


export function SvgToPng({setEngravingTextureObj, setBoltsTextureObj}) {
	
	const engraving_canvasRef = React.useRef();
	const engraving_ctxRef = React.useRef();
	const engraving_compositedCanvasRef = React.useRef();

	async function convertEngravingSvg(target) {

		const compositeEngravingTexture = async (img, svg) => {
			engraving_ctxRef.current = engraving_canvasRef.current.getContext('2d');

			let svgString = new XMLSerializer().serializeToString(svg);
			// makes 2px of padding
			let options = {
				scaleWidth: 510,
				scaleHeight: 510,
			}
			engraving_compositedCanvasRef.current = Canvg.fromString(engraving_ctxRef.current, svgString, options);

			await engraving_compositedCanvasRef.current.render();

			let dynamicPngSrcResult = engraving_canvasRef.current.toDataURL("image/png");

			setEngravingTextureObj({
				textureBase64Src: dynamicPngSrcResult,
				srcImgAspectRatio: img.width / img.height
			})
		}

		await SVGInject(target, {
			copyAttributes: true,
			beforeInject: changeColor,
			afterInject: compositeEngravingTexture
		}); 

	}
	


	const bolts_canvasRef = React.useRef();
	const bolts_ctxRef = React.useRef();
	const bolts_compositedCanvasRef = React.useRef();

	async function convertBoltsSvg(target) {

		const compositeBoltsTexture = async (img, svg) => {
			bolts_ctxRef.current = bolts_canvasRef.current.getContext('2d');

			let svgString = new XMLSerializer().serializeToString(svg);
			// makes 2px of padding
			let options = {
				scaleWidth: 1022,
				scaleHeight: 510,
			}
			bolts_compositedCanvasRef.current = Canvg.fromString(bolts_ctxRef.current, svgString, options);

			await bolts_compositedCanvasRef.current.render();

			let dynamicPngSrcResult = bolts_canvasRef.current.toDataURL("image/png");

			setBoltsTextureObj({
				textureBase64Src: dynamicPngSrcResult,
				srcImgAspectRatio: img.width / img.height
			})
		}

		await SVGInject(target, {
			copyAttributes: true,
			beforeInject: changeColor,
			afterInject: compositeBoltsTexture
		}); 

	}
	








	function changeColor(img, svg) {
		let svgEls = svg.querySelectorAll('[fill="#B2B2B2"]');

		svgEls.forEach((svgEl) => {
			// svgEl.setAttribute('fill', "#000000");
		})
	}


	return (
		<>
		{/* engraving */}
		<canvas id="engravingCanvas" ref={engraving_canvasRef} width='512' height='512' style={{display: 'none'}}></canvas>
		<img className="svgPreview" src="/mgp-caliper-models/textures/engravings/marines-logo.svg" onLoad={(e) => convertEngravingSvg(e.target)} />
		{/* <img className="svgPreview" src="/mgp-caliper-models/textures/engravings/us-army-logo.svg" onLoad={(e) => convertEngravingSvg(e.target)} /> */}
		{/* <img className="svgPreview" src="/mgp-caliper-models/textures/engravings/bmw_logo.svg" onLoad={(e) => convertEngravingSvg(e.target)} /> */}
		{/* <img className="svgPreview" src="/mgp-caliper-models/textures/engravings/m-logo.svg" onLoad={(e) => convertEngravingSvg(e.target)} /> */}
		{/* <img className="svgPreview" src="/mgp-caliper-models/textures/engravings/SemperFi_logo.svg" onLoad={(e) => convertEngravingSvg(e.target)} /> */}

		
		
		<canvas id="boltsCanvas" ref={bolts_canvasRef} width='1024' height='512' style={{display: 'none'}}></canvas>
		<img className="svgPreview" src="/mgp-caliper-models/textures/bolts/OLD/F2-bolts.svg" onLoad={(e) => convertBoltsSvg(e.target)} />
		</>
	)

}