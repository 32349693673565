import React, {useState, useEffect, useRef} from "react";

import { LinearProgress } from "@material-ui/core";

import './styles.scss'

export const SceneLoadingScreen = ({ componentsActiveId }) => {

	const [isVisible, setIsVisible] = useState(true);
	const [itemLoadedCount, setItemLoadedCount] = useState(0);
	const [areEnvironmentAssetsLoaded, setAreEnvironmentAssetsLoaded] = useState(false);

	// we'll setup event listeners to know when all item's 3D assets have been loaded
	// then we'll make the loading screen invisible
	useEffect(() => {
		document.addEventListener('ItemAssetsLoaded', handleItemAssetsLoaded)
		document.addEventListener('EnvironmentAssetsLoaded', handleEnvironmentAssetsLoaded)
		return () => {
			document.removeEventListener('ItemAssetsLoaded', handleItemAssetsLoaded)
			document.removeEventListener('EnvironmentAssetsLoaded', handleEnvironmentAssetsLoaded)
		}
	}, [])

	// setup timer for loading screen 
	// const [isMinTimerDone, _setIsMinTimerDone] = useState(true);
	// const isMinTimerDoneRef = useRef(true);
	// const setIsMinTimerDone = (newState) => {
	// 	isMinTimerDoneRef.current = newState;
	// 	_setIsMinTimerDone(newState);
	// }
	
	// Whenever a new scene is visited, we want to show the loading screen while it's assets are loading
	// useEffect(() => {
	// 	setIsVisible(true);
	// 	setItemLoadedCount(0);
	// 	// Show loading screen for a minimum of 1000ms so animations and such can occur properly
	// 	if (isMinTimerDoneRef.current) {
	// 		setIsMinTimerDone(false);
	// 		setTimeout(() => { setIsMinTimerDone(true) }, 1000);
	// 	}
	// }, [componentsActiveId])

	// checking if everything's loaded
	useEffect(() => {
		// TODO: check for caliper assets
		if (areEnvironmentAssetsLoaded) {
			setIsVisible(false);
      document.dispatchEvent(new CustomEvent('SceneIsBeingRevealed'))
		}
		// if (itemLoadedCount >= 1 && areEnvironmentAssetsLoaded) {
		// 	setIsVisible(false);
    //   document.dispatchEvent(new CustomEvent('SceneIsBeingRevealed'))
		// }
	}, [itemLoadedCount, areEnvironmentAssetsLoaded])


	function handleItemAssetsLoaded(e) {
		setItemLoadedCount(itemLoadedCount => itemLoadedCount + 1);
	}

	function handleEnvironmentAssetsLoaded() {
		setAreEnvironmentAssetsLoaded(true);
	}





	return (
		<>
		{isVisible &&
			<div className="sceneLoadingScreen d-flex justify-content-center align-items-center" >
				<div className='d-flex flex-column justify-content-center align-items-center' style={{width: '50%', textAlign: 'center'}}>
					<img className="mb-5" src="/images/logo.svg" />
					<LinearProgress />
				</div>
			</div>
		}
		</>
	)
}
