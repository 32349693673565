import React from "react";
import { BuilderScreen } from './UI/BuilderScreen/BuilderScreen';
import { BuilderUI } from './UI/BuilderUI/BuilderUI';
import Scene from "./r3f/Scene.js";


export default function ProductBuilder() {
	

  return (
		<>
			<BuilderScreen>
				<BuilderUI />
				<Scene />
			</BuilderScreen>
		</>
  );
}
