import { BuildSection } from '../BuildSection/BuildSection';
import { SceneLoadingScreen } from '../SceneLoadingScreen/SceneLoadingScreen';
import { CustomLoader } from "../CustomLoader/CustomLoader";
import { HUD } from '../HUD/HUD';
import Container from 'react-bootstrap/Container';
import Stepper from '../CustomStepper/CustomStepper';
import './styles.scss';

import {useAtom} from 'jotai';
import {
  products_state,
  update_products_activeId,
  components_state,
  update_components_activeId,
  items_state,
  update_items_activeIdObj
} from '../../dataManagers/GlobalDataManagers'


export const BuilderUI = () => {


  // products
  const [productsState] = useAtom(products_state);
  const [, setProductsActiveId] = useAtom(update_products_activeId);
  
  // components
  const [componentsState] = useAtom(components_state);
  const [, setComponentsActiveId] = useAtom(update_components_activeId);
  
  // items
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIdObj] = useAtom(update_items_activeIdObj);


  return (
    <>
      <Container fluid className="BuilderUI p-0">
        
        {/* UI section on left (desktop) or bottom (mobile) of screen */}
        { productsState.isPrimed && componentsState.isPrimed &&
          <BuildSection headerData={ productsState } >
            <Stepper stepperData={componentsState} setBuildSectionStepper={setComponentsActiveId} />
          </BuildSection>
        }
         

        {/* UI section overlaying the 3D scene */}
        {/* Uses .shared-scene-sizing to mimic the scene's canvas. */}
        <HUD>
          {/* loading screen used for the initial load - it covers the scene's canvas */}
          <SceneLoadingScreen componentsActiveId={componentsState.activeId} />
          {/* transparent loader for when assets are loading dynamically but we don't need to cover up whole screen */}
          {/* {buildSectionStepper.activeIndex === 2 &&
            <CustomLoader />
          } */}

          {/* <ProductTour buildSectionStepperActiveIndex={ buildSectionStepper.activeIndex } /> */}

          {/* popup to inform the user to grab the scene to move camera or platform */}
          {/* <GrabSceneIndicator buildSectionStepperActiveIndex={buildSectionStepper.activeIndex} /> */}

          {/* <WidgetsContainer>
            <WidgetCustomerSupport />
            { buildSectionStepper.activeIndex > 0 &&
              <>
                <WidgetToggleSizeInfo baseData={baseData} sizeData={sizeData} isDetailsActive={sizeInfo.isActive} setDetails={setSizeInfo} />
                <WidgetCopyURL />
              </>
            }
          </WidgetsContainer> */}
        </HUD>
      </Container>
    </>
  )
}