import React, { useState, useEffect } from "react";
import {Router, useLocation, useRoute} from 'wouter';

import {GlobalDataManagers} from './GlobalDataManagers';


/**
 * 
 * This component manages reading and writing the URL, which defines the global state data
 * Reads active id's from URL and passes them down to the global data managers
 * Writes URL whenever global data managers call function, which was passed down to them, to update an activeId
 * 
 */

// URL params
// Ex. fitments/:fitmentId/customize/:productActiveId/:componentActiveId/:itemsActiveIdObj
const fitmentParam = "fitmentId";
const productParam = "productActiveId";
const componentParam = "componentActiveId";
const itemsParam = "itemsActiveIdObj";



export function UrlDataController({children}) {
  
  const paramsObj = usePathParams();

  /**
   * 
   * fitment (coming from MGP wordpress site)
   * 
   */

  const [fitment_activeId] = useState(paramsObj[fitmentParam]);
  


  /**
   * 
   * products
   * 
   */

  const [products_activeId, set_products_activeId] = useState(paramsObj[productParam]);

  function update_products_activeId_inURL(newId) {
    set_products_activeId(newId);
  }



  /**
   * 
   * components
   * 
   */
  
  const [components_activeId, set_components_activeId] = useState(paramsObj[componentParam]);
  
  function update_components_activeId_inURL(newId) {
    console.log(`update_components_activeId_inURL: `, newId)
    set_components_activeId(newId);
  }
  
  

  
  /**
   * 
   * items
   * 
   */
  

  // helpers to endcode and decode activeIdObj for URL
  function jsonToURI(json){ return encodeURIComponent(JSON.stringify(json)); }
  function uriToJSON(uri){ return JSON.parse(decodeURIComponent(uri)); }

  const [items_activeIdObj, set_items_activeIdObj] = useState(uriToJSON(paramsObj[itemsParam]));

  function update_items_activeIdObj_inURL(newIdObj) {
    console.log(`update_items_activeIdObj_inURL: `, newIdObj)
    set_items_activeIdObj(newIdObj);
  }

  



  /**
   * 
   * 
   * URL updates whenever an active id changes, which is always triggered internally (above)
   * 
   * 
   */

  const [location, setLocation] = useLocation();
  // console.log(`location`, location)
 
  function updateURL() {
    if (!fitment_activeId) return;

    let pathname = `/fitments/${fitment_activeId}/customize`;

    if (products_activeId) {
      pathname += `/${products_activeId}`;
    }

    if (components_activeId) {
      pathname += `/${components_activeId}`;
    }

    if (items_activeIdObj) {
      // encoding obj to string
      pathname += `/${jsonToURI(items_activeIdObj)}`
    }

    if (location.pathname != pathname) {
      setLocation(pathname);
    }
  }

  useEffect(() => {
    updateURL();
  }, [fitment_activeId, products_activeId, components_activeId, items_activeIdObj])
  






  return (
    <Router basePath={'/'}>
      <GlobalDataManagers
        // products 
        products_activeId_fromURL={products_activeId}
        update_products_activeId_inURL={update_products_activeId_inURL}
        // components 
        components_activeId_fromURL={components_activeId}
        update_components_activeId_inURL={update_components_activeId_inURL}
        // items 
        items_activeIdObj_fromURL={items_activeIdObj}
        update_items_activeIdObj_inURL={update_items_activeIdObj_inURL}
      />

      {/* children is the ProductBuilder */}
      {children}
    </Router>
  )

}


const usePathParams = () => {

  const [location] = useLocation();
  const [fitmentMatch, fitmentParams] = useRoute(`/fitments/:${fitmentParam}/customize`);
  const [productMatch, productParams] = useRoute(`/fitments/:${fitmentParam}/customize/:${productParam}`);
  const [componentMatch, componentParams] = useRoute(`/fitments/:${fitmentParam}/customize/:${productParam}/:${componentParam}`);
  const [itemsMatch, itemsParams] = useRoute(`/fitments/:${fitmentParam}/customize/:${productParam}/:${componentParam}/:${itemsParam}`);

  // setup params template with default values
  let paramsTemplate = {};
  paramsTemplate[fitmentParam] = "2013-bmw-128i-base";
  paramsTemplate[productParam] = "22200S";
  paramsTemplate[componentParam] = null;
  paramsTemplate[itemsParam] = null;
  
  // edit params template according to current path
  if (location === "/") {
    return paramsTemplate;
  }
  else if (fitmentMatch) {
    paramsTemplate[fitmentParam] = fitmentParams[fitmentParam];
  }
  else if (productMatch) {
    paramsTemplate[fitmentParam] = productParams[fitmentParam];
    paramsTemplate[productParam] = productParams[productParam];
  }
  else if (componentMatch) {
    paramsTemplate[fitmentParam] = componentParams[fitmentParam];
    paramsTemplate[productParam] = componentParams[productParam];
    paramsTemplate[componentParam] = componentParams[componentParam];
  }
  else if (itemsMatch) {
    paramsTemplate[fitmentParam] = itemsParams[fitmentParam];
    paramsTemplate[productParam] = itemsParams[productParam];
    paramsTemplate[componentParam] = itemsParams[componentParam];
    paramsTemplate[itemsParam] = itemsParams[itemsParam];
  }

  return paramsTemplate;

}

