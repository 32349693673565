import React, {useRef, useEffect} from "react";
import {useThree, useLoader} from "@react-three/fiber";
import {Environment} from "@react-three/drei";

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { KTX2Loader } from "../../../scripts/PatchedKTX2Loader.js";

export default function EnvironmentController() {
	
	const camera = useThree(({ camera }) => camera);
	const scene = useThree(({ scene }) => scene);

	// attach directional light to the camera
	// const directionalLight = useRef();
	useEffect(() => {
		// camera.add(directionalLight.current);
		scene.add(camera)
	}, [])
	
	
	// since there's a Suspense wrapping this component, this won't run until after all children components have loaded and rendered
	// signaling to the loading screen we're ready to go
	useEffect(() => {
		document.dispatchEvent(new CustomEvent("EnvironmentAssetsLoaded"))
	}, [])


	// set the background color of the scene
	useEffect(() => {
		document.getElementById('builder-scene-canvas-container').style.background = "black";
	}, [])
 

  return (
    <>

      {/* <directionalLight ref={directionalLight} args={['white', 1]} position={[0.5, 0, 0.866]} /> */}
      <spotLight args={['white', 5]} position={[0, 5, 2]} />
      <ambientLight args={['white', 0.5]} />


			{/* environment lighting */}
			<Environment
				background={false}
				preset={'night'}
				scene={undefined} // adds the ability to pass a custom THREE.Scene
			/>

			{/* surrounding scene */}
			<FloorModel/>
			<BackdropModel/>
			


		</>
  );
}



function FloorModel() {

	// load floor model
	const floorModel = useLoader(GLTFLoader, '/mgp-caliper-models/scenery/models/floor.glb', (loader) => {
		loader.setKTX2Loader( new KTX2Loader() );
	})

	return <primitive object={floorModel.scene} />
}


function BackdropModel() {

	// load backdrop model
	const backdropModel = useLoader(GLTFLoader, '/mgp-caliper-models/scenery/models/backdrop.glb', (loader) => {
		loader.setKTX2Loader( new KTX2Loader() );
	})

	return <primitive object={backdropModel.scene} />
}

