import React from 'react';
import ReactDOM from 'react-dom';
import ProductBuilder from './components/ProductBuilder';
import { UrlDataController } from './components/dataManagers/UrlDataController';

import '../globals.scss'

ReactDOM.render(
  <React.StrictMode>
    <UrlDataController>
      <ProductBuilder />
    </UrlDataController>
  </React.StrictMode>,
  document.getElementById('react-root')
);